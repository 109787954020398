<template>
  <validation-observer ref="simpleRules">
    <div>
      <div class="card">
        <div class="nav-panel anan-tabs anan-tabs-line anan-tabs-normal anan-tabs-top">
          <div class="anan-tabs__nav">
            <div class="anan-tabs__nav-warp px-2" style="margin-top: 5px;">
              <div class="anan-tabs__nav-tabs" style="transform: translateX(0px)">
                <div class="anan-tabs__nav-tab" :class="{ active: isActive === '0' }" style="white-space: normal"
                  @click="isActive = '0', getData()">
                  <span> {{ $t('announcement') }} </span>
                </div>

                <div class="anan-tabs__nav-tab" :class="{ active: isActive === '1' }" style="white-space: normal"
                  @click="isActive = '1', getDetailSend()">
                  <span> ส่งเมล์ </span>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="d-lg-flex justify-content-between align-items-center px-2 py-1">
          <div>
            <!-- <h1>{{ $t('test') }}</h1> -->

            <h3 v-if="isActive === '0'" class="font-weight-bolder">
              {{ $t('announcement') }}
            </h3>
            <h3 v-if="isActive === '1'" class="font-weight-bolder">
              {{ $t('key-84') }}
            </h3>
          </div>

          <div class="d-flex align-items-center">
            <div class="anan-input__inner anan-input__inner--normal" v-if="isActive === '0'">
              <input v-model="search_val" type="text" :placeholder="$t('search')" class="anan-input__input"
                @keyup.enter="getData()">
              <div class="anan-input__suffix">
                <button type="button" class="anan-button anan-button--normal anan-button--primary" @click="getData()">
                  <i class="fal fa-search" />
                </button>
              </div>
            </div>
            <div class="anan-input__inner anan-input__inner--normal" v-if="isActive === '1'">
              <input v-model="search_val" type="text" :placeholder="$t('search')" class="anan-input__input"
                @keyup.enter="getDetailSend()">
              <div class="anan-input__suffix">
                <button type="button" class="anan-button anan-button--normal anan-button--primary" @click="getDetailSend()">
                  <i class="fal fa-search" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="anan-input__suffix">
          <router-link :to="{ name: 'admin-notice-add' }">
            <button v-if="isActive === '0'" class="btn btn-primary ml-2">
              <i class="far fa-plus mr-25" /> {{ $t('addAnnouncement') }}
            </button>
          </router-link>
        </div>
        <b-overlay :show="showOver" variant="transparent" opacity="0.99" blur="5px" rounded="sm">
          <template #overlay>
            <div class="text-center">
              <p id="cancel-label">
                กรุณารอสักครู่...
              </p>
            </div>
          </template>
          <div v-if="isActive === '0'" class="pl-2 pr-2 pt-1">
            <b-table striped hover responsive :per-page="perPage" :items="items" :fields="fields" show-empty>
              <template #empty>
                <div class="text-center">
                  <img src="@/assets/images/anan-img/empty/empty.png" alt="empty" width="180px">
                  <p>No Data</p>
                </div>
              </template>

              <template #cell(img)="data">
                <img v-if="data.item.file_file" :src="GetImg('Notice_file', data.item.file_file)"
                  class="aws-avatar aws aws-avatar-square aws-avatar-image bordered cursor-pointer"
                  @click="showPicture(data.item.file_file)">
                <span v-else>-</span>
              </template>

              <template #cell(number)="data">
                {{ items.length - (perPage * (currentPage - 1) + (data.index + 1)) + 1 }}
              </template>
              <template #cell(ice)="data">
                {{ data.item.postName }}
              </template>
              <template #cell(timeAdd)="data">
                {{ time(data.item.created_at) }}
              </template>

              <template #cell(status)="data">
                <b-form-checkbox v-model="data.item.active" class="custom-control-success" :value="true" switch
                  @change="Updateitem(data.item)">
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </template>

              <template #cell(action)="data">
                <feather-icon
                icon="MailIcon"
                class="mr-1 text-primary cursor-pointer"
                @click="EmailSend(data.item)"
              />
                <feather-icon icon="Edit3Icon" class="mr-1 text-primary cursor-pointer"
                  @click="$router.push({ name: 'admin-notice-edit', params: { id: data.item._id } })" />
                <feather-icon class="mr-1 text-danger cursor-pointer" icon="TrashIcon" @click="Deleteitem(data.item)" />
              </template>
            </b-table>

            <div class="demo-spacing-0 d-flex justify-content-end mb-2">
              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number
                prev-class="prev-item" next-class="next-item" class="mb-0" @input="getData()">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </b-overlay>

        <div v-if="isActive === '1'" class="pl-2 pr-2 pt-1">
          <b-table striped hover responsive :items="items2"
            :fields="fields2" :sort-by.sync="sortBy" :per-page="perPage" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
            :filter="filter" :filter-included-fields="filterOn" show-empty>
            <template #empty>
              <div class="text-center">
                <img src="@/assets/images/anan-img/empty/empty.png" alt="empty" width="180px">
                <p>No Data</p>
              </div>
            </template>
            <template #cell(sendcout)="data">
              {{ data.item.amount }}/{{ data.item.amount_all }}
            </template>
            <template #cell(created_at)="data">
              {{ time(data.item.created_at) }}
            </template>

            <template #cell(status)="data">
              <b-badge variant="success" v-if="data.item.status">
                <span>{{ $t('completed') }}
                </span>
              </b-badge>
              <b-badge variant="danger" v-else>
                <span>กำลังส่งเมล์
                </span>
              </b-badge>
            </template>

          </b-table>

          <div class="demo-spacing-0 d-flex justify-content-end mb-2">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number
              prev-class="prev-item" next-class="next-item" class="mb-0" @input="getDetailSend()">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </div>
    </div>

    <vue-image-lightbox-carousel ref="lightbox" :show="showLightbox" :images="images" :show-caption="false"
      @close="closeBox()" />

  </validation-observer>
</template>

<script>
import {
  BTable,
  // BModal,
  // BFormGroup,
  // BFormInput,
  //   BCardText,
  BPagination,
  BFormCheckbox,
  // BFormTextarea,
  BBadge,
  BOverlay,
} from 'bootstrap-vue'
import {
  // ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import { required } from '@validations'
import moment from 'moment-timezone'
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'

export default {
  components: {
    BTable,
    // BCardText,
    ValidationObserver,
    BPagination,
    BFormCheckbox,
    BBadge,
    BOverlay,
    VueImageLightboxCarousel,
  },
  data() {
    return {
      images: [],
      showLightbox: false,
      isActive: '0',
      required,
      pageOptions: [5, 10, 15, 20],
      currentPage: 1,
      perPage: 10,
      totalRows: 1,
      // fields: [
      //   {
      //     key: 'number', label: 'หมายเลขรายการ', thStyle: { width: '15%' }, thClass: 'text-center', tdClass: 'text-center',
      //   },
      //   {
      //     key: 'ice', label: 'ประกาศ', sortable: false, thStyle: { width: '30%' }, thClass: 'text-center', tdClass: 'text-center',
      //   },
      //   {
      //     key: 'img', label: 'รูปปก', sortable: false, thStyle: { width: '20%' }, thClass: 'text-center', tdClass: 'text-center',
      //   },
      //   {
      //     key: 'timeAdd', label: 'วันที่สร้าง', sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
      //   },
      //   {
      //     key: 'status', label: 'ใช้งานอยู่', sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
      //   },
      //   {
      //     key: 'action', label: 'จัดการ', sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
      //   },
      // ],
      items2: [
        // {
        //   announce: "ทดสอบนะจ้ะนะจ้ะนะจ้ะ", sendtime: '10/10/2567', sendcout: 128, status: 1,
        // },
        // {
        //   announce: "ลองดูลองเฉยๆ ลอง", sendtime: '10/10/2567', sendcout: 18, status: 1,
        // },
      ],
      search_val: null,
      showOver: false,
      Checkitems2: false
    }
  },
  computed: {
    fields2() {
      return [
        {
          key: 'postName', label: this.$t('announcement'), thStyle: { width: '60%' }, thClass: 'text-center', tdClass: 'text-left',
        },
        {
          key: 'created_at', label: "วันที่ส่ง", thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'sendcout', label: "จำนวนที่ส่ง", thStyle: { width: '15%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'status', label: "สถานะ", thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },
    fields() {
      return [
        {
          key: 'number', label: this.$t('orderNumber'), thStyle: { width: '15%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'ice', label: this.$t('announcement'), sortable: false, thStyle: { width: '30%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'img', label: this.$t('coverImage'), sortable: false, thStyle: { width: '20%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'timeAdd', label: this.$t('creationDate'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'status', label: this.$t('activeUse'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'action', label: this.$t('manage'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },
  },
  mounted() {
    this.getData()
  },
  // created() {
  //   this.interval = setInterval(() => {
  //     // this.getUserslist()
  //     if (this.isActive === '1' && this.Checkitems2) {
  //       this.getDetailSend()
  //     }
  //   }, 1500)
  // },
  // destroyed() {
  //   clearInterval(this.interval)
  // },
  methods: {
    time(data) {
      return moment(data).format('YYYY-MM-DD HH:mm:ss')
    },
    
    EmailSend(data) {
      this.$swal({
        title: '<h3 style="color: #141414">ต้องการส่งเมล์</h3>',
        icon: 'warning',
        showCancelButton: true,
        text: `ส่งเมล์ ${data.postName} ?`,
        cancelButtonText: 'ยกเลิก',
        confirmButtonText: 'ยืนยัน',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
          // this.showOver = true
          const params = {
            ID: data._id,
          }
          this.isActive = '1'
          this.runDetail()
          this.$http.post('/Notice/SendEmail/data', params)
            .then(response => {
              // this.getDetailSend()
              if (response.data.status) {
                this.runDetail()
                // this.Checkitems2 = true 
                // this.$swal({
                //   icon: 'success',
                //   title: '<h3 style="color: #141414">สำเร็จ</h3>',
                //   html: `<p style="color: #141414">ส่งเมล์ ${data.postName} สำเร็จ </p>`,
                //   showConfirmButton: false,
                //   timer: 2000,
                // })
              
              }
            }).catch(err => {
              this.$swal({
                icon: 'error',
                title: '<h3 style="color: #141414">ไม่สำเร็จ</h3>',
                html: `<p style="color: #141414">ส่งเมล์ ${data.postName} ไม่สำเร็จ</p>`,
                showConfirmButton: false,
                timer: 2000,
              })
              // this.showOver = false
              console.log(err)
            })
        }
      })
    },
    async getDetailSend() {
      console.debug('1')
      this.showOver = true
      try {
        const params = {
          search: this.search_val,
          page: this.currentPage,
          perPage: this.perPage,
        }
        const { data: res } = await this.$http.get('/Notice/SendEmail/Detail', { params })
        this.items2= res.data
        this.totalRows = res.total
        // this.getDataIMG()
        this.showOver = false
      } catch (err) {
        console.log(err)
          this.showOver = false
      }
    },
    async getData() {
      console.debug('0')
      this.showOver = true
      try {
        const params = {
          search: this.search_val,
          page: this.currentPage,
          perPage: this.perPage,
        }
        const { data: res } = await this.$http.get('/Notice/getData', { params })
        // res.data.map(ele => {
        //   ele.file_file = null
        // })
        this.items = res.data
        this.totalRows = res.total
        // this.getDataIMG()
        this.showOver = false
      } catch (err) {
        console.log(err)
        this.showOver = false
      }
    },
    // getDataIMG() {
    //   const ID = this.items.map(ele => ele._id)
    //   const params = {
    //     ID,
    //   }
    //   this.$http.get('Notice/getDataNoticeImg', { params })
    //     .then(response => {
    //       response.data.forEach((data, index) => {
    //         this.items[index].file_file = data.file_file
    //       })
    //     })
    //     .catch(err => {
    //       console.log(err)
    //     })
    // },
    Updateitem(data) {
      const obj = {
        // eslint-disable-next-line no-underscore-dangle
        id: data._id,
        active: data.active,
      }
      this.$http.post('/Notice/StoreNotice/data', obj)
        .then(() => {
          this.Success('บันทึกข้อมูลเรียบร้อย')
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.SwalError(err)
        })
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    runDetail() {
      setTimeout(() => {
        this.getDetailSend()
      }, 1000)
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    Deleteitem(data) {
      this.$bvModal.msgBoxConfirm('ต้องการลบข้อมูลหรือไม่ ?', {
        title: 'ยืนยันข้อมูล',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      }).then(result => {
        if (result) {
          const obj = {
            // eslint-disable-next-line no-underscore-dangle
            id: data._id,
          }
          this.$http.post('/Notice/Delete/data', obj)
            .then(() => {
              this.Success('ลบข้อมูลเรียบร้อย')
              this.getData()
            })
            .catch(err => {
              console.log(err)
              this.SwalError(err)
            })
        }
      })
    },
    GetImg(pathfile, filename) {
      const matches = filename.match(/^data:image\/([A-Za-z-+\/]+);base64,(.+)$/)
      if (!matches || matches.length !== 3) {
        return `${this.$http.defaults.baseURL}/viewimg?pathfile=${pathfile}/${filename}`
      }
      return filename
    },
    showPicture(picture) {
      this.showLightbox = true
      const img = { path: this.GetImg('Notice_file', picture) }
      this.images.push(img)
      // this.$swal({
      //   imageUrl: picture,
      //   imageWidth: 400,
      //   imageHeight: 400,
      //   imageAlt: 'Custom image',
      //   confirmButtonText: 'ปิด',
      // })
    },
    closeBox() {
      this.showLightbox = false
      this.images = []
    },
  },
}
</script>

<style lang="scss" scoped></style>
